import styled from 'styled-components'
import Image from 'gatsby-image'
// import { Container as CommonContainer } from 'components/common/Container'

export const CommonContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: ${props => (props.noYPadding ? '0 2rem' : '2rem')};

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 600px) {
    padding: ${props => (props.noYPadding ? '0 1rem' : '1rem')};
  }

  ${props =>
    props.noMobilePadding &&
    `
  @media (max-width: 600px) {
    padding: 0;
  }`}
`

export const Container = styled(CommonContainer)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`

export const Article = styled.article`
  padding-bottom: 2rem;

  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
  }
`

export const BlogHeadContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  ${props => (props.full ? 'padding-right: 0 !important;\n' : '')}
  padding-top: ${props => (props.banner ? '0' : '2rem')};
  padding-bottom: ${props => (props.banner ? '2rem' : '1rem')};
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin: auto;
  justify-content: ${props => (props.centered ? 'center' : 'normal')};
  padding-top: 2rem;
`

export const Bullet = styled.span`
  font-size: 10px;
  top: -3px;
  position: relative;
`

export const BannerWrapper = styled.div`
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
`

export const Banner = styled(Image)`
  height: auto;
  width: 100%;
`

export const HeaderTitleContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.h1`
  text-align: ${props => (props.centered ? 'center' : 'left')};
`

export const PostMetadataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`

export const PostMetadata = styled.p`
  font-weight: 300;
  color: #999;
  margin-bottom: 0;
  text-align: ${props => (props.centered ? 'center' : 'left')};
`

export const AuthorWrapper = styled.div`
  text-align: center;
  padding: 1rem 0;
  flex-direction: row;
  display: flex;
  align-items: center;
`

export const AuthorTextWrapper = styled.div`
  display: block;
  padding-left: 1rem;
  text-align: left;
`

export const AuthorImage = styled(Image)`
  height: auto;
  width: 80px;
  max-width: 100%;
  border-radius: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  vertical-align: middle;
  border-style: none;
`

export const PrevNextArticleNav = styled.nav`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`

export const ShareButtons = styled.div`
  margin-bottom: 10px;

  button + button {
    margin-left: 5px;
  }
`
